import ApiService from "@/core/services/ApiService";

interface IHighlightPdf {
    _id : string;
    path : string;
    type : string;
    status : string;
    created_at : string;
    updated_at : string;
    name : string;
}

export { IHighlightPdf };

const ListPdfHighlight: Array<IHighlightPdf> = [];
export default ListPdfHighlight;

export const getPDFHighlight = async () => {
    const response = await ApiService.get("/files")
    return response.data;
}


export const deleteFile = async (id) => {
    const response = await ApiService.post("/files/delete/" + id, {});
    return response;
}